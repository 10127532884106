import React, { Component } from 'react'
import { graphql } from 'gatsby'

import Layout from "../components/layout"

class NewsItemTemplate extends Component {
  render() {
    const news = this.props.data.wordpressWpNews

    return (
      <Layout>
        <div id="blog">

          <div className="row mt-3">
            <div className="col-12">
              <h1 className="" dangerouslySetInnerHTML={{__html: news.title}}></h1>
            </div>
          </div>

          <div className="row">
            <div className="col-12" dangerouslySetInnerHTML={{__html: news.content}}></div>
          </div>

          <div className="row mt-3">
            <div className="col-12 text-center">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <p>Delen op</p>
                </li>
                <li className="list-inline-item">
                  <a href={`http://twitter.com/home?status=https://www.nanini.be/nieuws/${news.slug}/`} title="Delen op Twitter" target="_blank" rel="noopener noreferrer" className="btn btn-twitter">
                    <i className="fa fa-twitter"></i> Twitter
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href={`https://www.facebook.com/sharer/sharer.php?u=https://www.nanini.be/nieuws/${news.slug}/`} title="Delen op Facebook" target="_blank" rel="noopener noreferrer" className="btn btn-facebook">
                    <i className="fa fa-facebook"></i> Facebook
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href={`http://pinterest.com/pin/create/button/?url=https://www.nanini.be/nieuws/${news.slug}/&amp;media=&amp;description=`} title="Delen op Pinterest" target="_blank" rel="noopener noreferrer" className="btn btn-pinterest">
                    <i className="fa fa-pinterest"></i> Pinterest
                  </a>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </Layout>
    )
  }
}

export default NewsItemTemplate

export const query = graphql`
  query currentNewsQuery($id: String!) {
    wordpressWpNews(id: { eq: $id }) {
      wordpress_id
      title
      date(formatString: "MMMM DD, YYYY")
      content
      slug
    }
  }
`
